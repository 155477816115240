module controllers {
    export module master {

        interface IPackageDetailControllerScope extends ng.IScope {
        }

        interface IPackageDetailParams extends ng.ui.IStateParamsService {
            packageId: number;        
        }

        export class packageDetailCtrl {
            static $inject = ["$scope",
            "$rootScope",
            "generalService",
            "$q",
            "bsLoadingOverlayService",
            "entityService",
            "$timeout",
            '$uibModal',
            'permitService',
            '$state',
            '$stateParams',
            '$transitions',
            'statusService',
            'menuService',
            'cartonService',
            ];

            packageId: number;
            package: interfaces.master.ICartonHeader;
            IsLoading: boolean = false;
            breadCrumbDesc: string;

            gridPackageDetailApi: uiGrid.IGridApi;    
            gridPackageErrorApi: uiGrid.IGridApi;
            gridPackageQuotaApi:  uiGrid.IGridApi;

            currentPage: number = 1;
            pageSize: number = 10;

            selectedTab: number = 0;

            allowDeletePackages: boolean;

            constructor(private $scope: IPackageDetailControllerScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private permitService: interfaces.master.IPermitService,
                private $state: ng.ui.IStateService,
                private $stateParams: IPackageDetailParams,
                private $transitions: ng.ui.core.ITransition,
                private statusService: interfaces.master.IStatusService,
                private menuService: interfaces.applicationcore.IMenuService,
                private cartonService: interfaces.master.ICartonService,
            ) {
                this.packageId = $stateParams.packageId;
                this.getPackageViewModel();
                this.getPackagesDeleteRight();
            }

            getPackagesDeleteRight() {
                this.allowDeletePackages = false;
                this.menuService.getGTSConnectMenuItem(682).get((result: interfaces.applicationcore.IMenu) => {
                    if (result && result.name) {
                        this.allowDeletePackages = true;
                    }
                });
            }


            getPackageViewModel(){
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'packagedetail'
                },
                    () => {
                        return this.cartonService.getPackageViewModel().get({
                            packageId: this.packageId,
                        }, (result: interfaces.master.ICartonHeader) => {
                            
                            this.package = result;

                            this.gvwPackageDetails.data = this.package.CartonDetailList;
                            this.gvwPackageDetails.totalItems = this.package.CartonDetailList.length;

                            this.gvwPackageErrors.data = this.package.CartonErrorsList;
                            this.gvwPackageErrors.totalItems = this.package.CartonErrorsList.length;

                            this.gvwPackageQuota.data = this.package.CartonPreAllocationList;
                            this.gvwPackageQuota.totalItems = this.package.CartonPreAllocationList.length;

                            this.breadCrumbDesc = 'Package : ' + this.package.Carton;

                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            public DeletePackageClick(){
                this.generalService.displayConfirmationBox("Confirm?", "Are you sure you want to permanently delete Package ?").then((result: boolean) => {
                    if (result) {
                       this.DeletePackage();
                    }
                });
            }

            public DeletePackage(){
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'packagedetail'
                },
                    () => {
                        var paramsObject = {
                            packageId: this.packageId,
                        };
                        return this.cartonService.deletePackage().delete(paramsObject, (data: interfaces.applicationcore.IMessageHandler) => {
                            if (data.HasErrorMessage){
                                this.generalService.displayMessageHandler(data);
                            }   
                            else{
                                this.$state.go("^");
                            }
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                    });
            }

            public registerPackageDetailGridApi(gridApi: uiGrid.IGridApi) {
                this.gridPackageDetailApi = gridApi;   
            }

            public registerPackageErrorGridApi(gridApi: uiGrid.IGridApi) {
                this.gridPackageErrorApi = gridApi;   
            }

            public registerPackageQuotaGridApi(gridApi: uiGrid.IGridApi) {
                this.gridPackageQuotaApi = gridApi;   
            }


            public gvwPackageQuota: uiGrid.IGridOptions = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,                 
                enableFullRowSelection: false,
                enableRowSelection: true,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [10, 20, 50, 100],
                paginationPageSize: 10,
                enableVerticalScrollbar: 1,
                useExternalPagination: false,
                onRegisterApi: (gridApi) => { this.registerPackageQuotaGridApi(gridApi) },
                columnDefs: [
                    {
                        name: "SEQ",
                        displayName: "#",
                        field: "SequenceNumber",
                        width: 60,
                        enableCellEdit: false,
                        cellClass: 'text-left',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD  | number : 0}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    ,
                    {
                        name: "TRANSACTIONDATE",
                        displayName: "Transaction Date",
                        field: "TransactionDate",
                        width: 140,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD  | momentDateFilter: 'YYYY/MM/DD'}}">{{MODEL_COL_FIELD  | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    ,
                    {
                        name: "PERMIT",
                        displayName: "Permit",
                        field: "Permit",
                        width: 320,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}"><a href="{{grid.appScope.packageQuotaCtrl.ENV.ROOT_URL}}/ApplicationCore/GTS/index.html#!/MasterData/Permit/UpdatePermit/{{row.entity.PermitId}}" target="_blank">{{MODEL_COL_FIELD}}</a></div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    }, 
                    {
                        name: "QuotaAllocation",
                        displayName: "Quota Allocation",
                        field: "QuotaAllocation",
                        width: 180,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "Reference1",
                        displayName: "Reference 1",
                        field: "Reference1",
                        width: 140,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },{
                        name: "Reference2",
                        displayName: "Reference 2",
                        field: "Reference2",
                        width: 140,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "Value",
                        displayName: "Value",
                        field: "Value",
                        width: 100,
                        enableCellEdit: false,
                        cellClass: 'text-right',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD  | number : 2}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "Amount",
                        displayName: "Amount",
                        field: "Amount",
                        width: 100,
                        enableCellEdit: false,
                        cellClass: 'text-right',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD  | number : 2}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    ]
                }

            public gvwPackageErrors: uiGrid.IGridOptions = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,                 
                enableFullRowSelection: true,
                enableRowSelection: true,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [10, 20, 50, 100],
                paginationPageSize: 10,
                enableVerticalScrollbar: 1,
                useExternalPagination: false,
                onRegisterApi: (gridApi) => { this.registerPackageErrorGridApi(gridApi) },
                columnDefs: [
                    {
                        name: "SEQ",
                        displayName: "#",
                        field: "SequenceNumber",
                        width: 60,
                        enableCellEdit: false,
                        cellClass: 'text-left',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD  | number : 0}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "ERRORCODE",
                        displayName: "Error Code",
                        field: "ErrorCode",
                        width: 80,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "ERROR",
                        displayName: "Error",
                        field: "Error",
                        width: 450,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "SKU",
                        displayName: "SKU",
                        field: "SKUCode",
                        width: 80,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "SKUDESCRIPTION",
                        displayName: "Description",
                        field: "SKUDescription",
                        width: 250,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    
                    {
                        name: "RESTRICTED",
                        displayName: "Restricted",
                        field: "Restriced",
                        width: 80,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "LASTUPDATEDBY",
                        displayName: "Last Update By",
                        field: "LastUpdatedBy",
                        width: 100,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    }  ,
                    {
                        name: "LastUpdated",
                        displayName: "Last Updated On",
                        field: "LastUpdated",
                        width: 130,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD  | momentDateFilter: 'YYYY/MM/DD HH:MM:SS'}}">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD HH:MM:SS'}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    }
                   
                    ]
                }
            

            public gvwPackageDetails: uiGrid.IGridOptions = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,                 
                enableFullRowSelection: false,
                enableRowSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [10, 20, 50, 100],
                paginationPageSize: 10,
                enableVerticalScrollbar: 1,
                useExternalPagination: false,
                onRegisterApi: (gridApi) => { this.registerPackageDetailGridApi(gridApi) },
                columnDefs: [                                       
                    {
                        name: "SEQ",
                        displayName: "#",
                        field: "SequenceNumber",
                        width: 60,
                        enableCellEdit: false,
                        cellClass: 'text-left',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD  | number : 0}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "PRODUCT",
                        displayName: "Product",
                        field: "ProductCode",
                        width: 80,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "SKU",
                        displayName: "SKU",
                        field: "SKUCode",
                        width: 80,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "DESCRIPTION",
                        displayName: "Description",
                        field: "Description",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "ORIGIN",
                        displayName: "Origin",
                        field: "Origin",
                        width: 80,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    }  ,
                    {
                        name: "TARIFFCODE",
                        displayName: "Tariff Code",
                        field: "TariffCode",
                        width: 80,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "RESTRICTION",
                        displayName: "Restriction",
                        field: "Restriction",
                        width: 220,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    }       
                    ,
                    {
                        name: "PERMIT",
                        displayName: "Permit",
                        field: "Permit",
                        width: 220,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}"><a href="{{grid.appScope.packageQuotaCtrl.ENV.ROOT_URL}}/ApplicationCore/GTS/index.html#!/MasterData/Permit/UpdatePermit/{{row.entity.PermitId}}" target="_blank">{{MODEL_COL_FIELD}}</a></div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },                    
                    {
                        name: "SKUQty",
                        displayName: "Qty",
                        field: "Qty",
                        width: 60,
                        enableCellEdit: false,
                        cellClass: 'text-right',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD  | number : 2}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },       
                    {
                        name: "UOM",
                        displayName: "UOM",
                        field: "UoM",
                        width: 60,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "PRICE",
                        displayName: "Price",
                        field: "Price",
                        width: 80,
                        enableCellEdit: false,
                        cellClass: 'text-right',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD  | number : 2}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "CURRENCY",
                        displayName: "Currency",
                        field: "Currency",
                        width: 60,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    }
                    ]
                }
            



      
            

        }

        angular.module("app").controller("packageDetailCtrl", controllers.master.packageDetailCtrl);
    }
}